export const GTEX_TISSUE_NAMES = {
  adipose_subcutaneous: 'Adipose - Subcutaneous',
  adipose_visceral_omentum: 'Adipose - Visceral (Omentum)',
  adrenal_gland: 'Adrenal Gland',
  artery_aorta: 'Artery - Aorta',
  artery_coronary: 'Artery - Coronary',
  artery_tibial: 'Artery - Tibial',
  bladder: 'Bladder',
  brain_amygdala: 'Brain - Amygdala',
  brain_anterior_cingulate_cortex_ba24: 'Brain - Anterior cingulate cortex (BA24)',
  brain_caudate_basal_ganglia: 'Brain - Caudate (basal ganglia)',
  brain_cerebellar_hemisphere: 'Brain - Cerebellar Hemisphere',
  brain_cerebellum: 'Brain - Cerebellum',
  brain_cortex: 'Brain - Cortex',
  brain_frontal_cortex_ba9: 'Brain - Frontal Cortex (BA9)',
  brain_hippocampus: 'Brain - Hippocampus',
  brain_hypothalamus: 'Brain - Hypothalamus',
  brain_nucleus_accumbens_basal_ganglia: 'Brain - Nucleus accumbens (basal ganglia)',
  brain_putamen_basal_ganglia: 'Brain - Putamen (basal ganglia)',
  brain_spinal_cord_cervical_c_1: 'Brain - Spinal cord (cervical c-1)',
  brain_substantia_nigra: 'Brain - Substantia nigra',
  breast_mammary_tissue: 'Breast - Mammary Tissue',
  cells_ebv_transformed_lymphocytes: 'Cells - EBV-transformed lymphocytes',
  cells_transformed_fibroblasts: 'Cells - Transformed fibroblasts',
  cervix_ectocervix: 'Cervix - Ectocervix',
  cervix_endocervix: 'Cervix - Endocervix',
  colon_sigmoid: 'Colon - Sigmoid',
  colon_transverse: 'Colon - Transverse',
  esophagus_gastroesophageal_junction: 'Esophagus - Gastroesophageal Junction',
  esophagus_mucosa: 'Esophagus - Mucosa',
  esophagus_muscularis: 'Esophagus - Muscularis',
  fallopian_tube: 'Fallopian Tube',
  heart_atrial_appendage: 'Heart - Atrial Appendage',
  heart_left_ventricle: 'Heart - Left Ventricle',
  kidney_cortex: 'Kidney - Cortex',
  liver: 'Liver',
  lung: 'Lung',
  minor_salivary_gland: 'Minor Salivary Gland',
  muscle_skeletal: 'Muscle - Skeletal',
  nerve_tibial: 'Nerve - Tibial',
  ovary: 'Ovary',
  pancreas: 'Pancreas',
  pituitary: 'Pituitary',
  prostate: 'Prostate',
  skin_not_sun_exposed_suprapubic: 'Skin - Not Sun Exposed (Suprapubic)',
  skin_sun_exposed_lower_leg: 'Skin - Sun Exposed (Lower leg)',
  small_intestine_terminal_ileum: 'Small Intestine - Terminal Ileum',
  spleen: 'Spleen',
  stomach: 'Stomach',
  testis: 'Testis',
  thyroid: 'Thyroid',
  uterus: 'Uterus',
  vagina: 'Vagina',
  whole_blood: 'Whole Blood',
}

export const GTEX_TISSUE_COLORS = {
  adipose_subcutaneous: '#FF6600',
  adipose_visceral_omentum: '#FFAA00',
  adrenal_gland: '#33DD33',
  artery_aorta: '#FF5555',
  artery_coronary: '#FFAA99',
  artery_tibial: '#FF0000',
  bladder: '#AA0000',
  brain_amygdala: '#EEEE00',
  brain_anterior_cingulate_cortex_ba24: '#EEEE00',
  brain_caudate_basal_ganglia: '#EEEE00',
  brain_cerebellar_hemisphere: '#EEEE00',
  brain_cerebellum: '#EEEE00',
  brain_cortex: '#EEEE00',
  brain_frontal_cortex_ba9: '#EEEE00',
  brain_hippocampus: '#EEEE00',
  brain_hypothalamus: '#EEEE00',
  brain_nucleus_accumbens_basal_ganglia: '#EEEE00',
  brain_putamen_basal_ganglia: '#EEEE00',
  brain_spinal_cord_cervical_c_1: '#EEEE00',
  brain_substantia_nigra: '#EEEE00',
  breast_mammary_tissue: '#33CCCC',
  cells_ebv_transformed_lymphocytes: '#CC66FF',
  cells_transformed_fibroblasts: '#AAEEFF',
  cervix_ectocervix: '#FFCCCC',
  cervix_endocervix: '#CCAADD',
  colon_sigmoid: '#EEBB77',
  colon_transverse: '#CC9955',
  esophagus_gastroesophageal_junction: '#8B7355',
  esophagus_mucosa: '#552200',
  esophagus_muscularis: '#BB9988',
  fallopian_tube: '#FFCCCC',
  heart_atrial_appendage: '#9900FF',
  heart_left_ventricle: '#660099',
  kidney_cortex: '#22FFDD',
  liver: '#AABB66',
  lung: '#99FF00',
  minor_salivary_gland: '#99BB88',
  muscle_skeletal: '#AAAAFF',
  nerve_tibial: '#FFD700',
  ovary: '#FFAAFF',
  pancreas: '#995522',
  pituitary: '#AAFF99',
  prostate: '#DDDDDD',
  skin_not_sun_exposed_suprapubic: '#0000FF',
  skin_sun_exposed_lower_leg: '#7777FF',
  small_intestine_terminal_ileum: '#555522',
  spleen: '#778855',
  stomach: '#FFDD99',
  testis: '#AAAAAA',
  thyroid: '#006600',
  uterus: '#FF66FF',
  vagina: '#FF5599',
  whole_blood: '#FF00BB',
}
